const config = {

    // Landing Page
    landingcontent: {
        title: "New York-Style Bagels, Baked to Perfection and Preserved for Ultimate Satisfaction.",
        description: "For lingering bagels at day's end, slice and securely seal in a freezer bag to extend their delicious satisfaction for future enjoyment.",
        keywords: "bagels, baked, traditional, New York Style, boiled, perfection, stone hearth, authentic, slice, seal, freezer bag, satisfaction, family-owned, operated, dough, made in-house, morning, tradition, kettle-boil, experience, crafted excellence, products, carefully, fresh daily, passion, deliver, best, service, customers, friends, indulgence, perfection, haven, commitment, finest, try, today, love, favorite, menu, 15 years, serving, area, breakfast, satisfying mornings, local source, crunchy crust, chewy center, preserved",
    },

    // About Us Page
    aboutcontent: {
        title: "15 Years of Authenticity, Tradition, and Passion",
        subtitle: "Family-Owned Excellence in Serving the Area with Freshly Made, Authentic New York Bagels and Breakfast Delights Every Morning.",
        description: "Explore the freshness of our carefully and traditionally made products, a daily testament to our unwavering commitment to quality. Join us in savoring the essence of tradition, taste, and community at The Main Bagel.",
        keywords: "bagel shop, 15 years, culinary excellence, The Main Bagel, family-owned, authentic, New York-style bagels, breakfast delights, tradition, in-house, morning, kettle-boil, bake, perfection, experience, passion, best, product, service, customers, friends, freshness, carefully made, daily, commitment, quality, savoring, essence, taste, community",
        message: "Experience the essence of tradition, taste, and community at The Main Bagel – where every bite tells a story.",
    },
    aboutbusiness: {
        paragraph1: "Indulge in 15 years of culinary excellence at The Main Bagel, where tradition meets authenticity. Our family-owned haven crafts New York-style bagels and breakfast delights in-house every morning, ensuring perfection while you sleep. ",
        paragraph2: "Join us on a journey of passion, delivering the best to our valued customers and friends. Immerse yourself in the freshness of our carefully made daily delights, a testament to our unwavering commitment to quality.",
    },
    littleaboutus: {
        paragraph1: "Start your day off right with a hearty breakfast. Our specialties include but are not limited to: the monte cristo, French toast, cinnamon rolls, biscuits and gravy, and much more.",
        paragraph2: "Our bagels are mixed, prepared, and baked in house everyday. We offer a variety of bagels and home made cream cheeses.",
        paragraph3: "Enjoy a freshly brewed cup of coffee, espresso, or a Big Train caffeinated or non-caffeinated drink with your entrée.",
    },

    // Contact Page
    contactcontent: {
        title: "Join us for breakfast, brunch or lunch with coffee and specialty drinks.",
        description: "Connect with The Main Bagel – a culinary legacy spanning 15 years, where family-owned passion and authentic New York-style excellence converge. We're dedicated to crafting the finest bagels and breakfast delights, with our dough made in-house every morning, kettle-boiled, and baked to perfection while you rest. Our commitment extends beyond the kitchen, embracing a tradition of delivering the best to our cherished customers and friends. Reach out to us to share your experience, inquire about our meticulously made daily offerings, or simply connect with a community that appreciates the essence of tradition and taste. Your satisfaction is our priority, and we look forward to hearing from you at The Main Bagel",
        keywords: "The Main Bagel, contact us, culinary legacy, 15 years, family-owned, authentic, New York-style, excellence, finest, bagels, breakfast delights, in-house, dough, kettle-boiled, baked to perfection, commitment, tradition, delivering the best, customers, friends, satisfaction, community, connection, experience, inquiries, daily offerings, meticulous, passion, essence, taste",
        message: "Connect with us to experience tradition, passion, and the finest in every bite."
    },

    // Photo Gallery Page
    gallerycontent: {
        title: "The Main Bagel's Visual Journey of Tradition and Taste",
        description: "Immerse yourself in 15 years of culinary excellence, where each image tells a story of family-owned passion, authentic New York-style craftsmanship, and the meticulous creation of the finest bagels and breakfast delights. Witness our daily commitment to tradition, from in-house dough preparation to the art of kettle-boiling and baking to perfection while the world sleeps. Join us on a journey through snapshots that capture the essence of our commitment to delivering the best to our valued customers and friends. Every image is a glimpse into the freshness, tradition, and community that defines The Main Bagel experience. Feast your eyes on the visual delights that await you in our gallery, and imagine the taste sensations that accompany each lovingly crafted creation.",
        keywords: "photo gallery, The Main Bagel, culinary excellence, 15 years, family-owned, authentic, New York-style, craftsmanship, finest, bagels, breakfast delights, tradition, in-house, dough preparation, kettle-boiling, baking, perfection, commitment, visual feast, snapshots, delivering the best, customers, friends, journey, freshness, community, taste sensations",
        message: "Explore the visual feast at The Main Bagel through our captivating photo gallery",
    },

    // Menu Page
    ordercontent: {
        title: "Savor the Flavor: The Main Bagel's Culinary Symphony Menu",
        description: "Dive into a delectable journey at The Main Bagel's Menu Page. Our 15-year legacy of family-owned passion and authentic New York-style mastery unfolds in each meticulously crafted item. From our in-house dough preparations to the artful kettle-boiling and perfection in baking, our menu is a testament to our commitment to tradition and excellence. Explore a symphony of flavors, featuring the finest bagels and breakfast delights that have delighted our cherished customers and friends. Join us in savoring the essence of freshness, tradition, and community with every bite.",
        keywords: "menu, The Main Bagel, culinary journey, family-owned, authentic, New York-style, mastery, in-house, dough preparations, kettle-boiling, perfection, commitment, tradition, excellence, symphony of flavors, finest, bagels, breakfast delights, cherished customers, friends, savor, essence, freshness, community, culinary delights",
        message: "Browse through our offerings and imagine the culinary delights that await you at The Main Bagel.",
    },

    // Eloyalty Page
    eloyaltycontent: {
        title: "Elevate Your Experience: The Main Bagel's eLoyalty Hub",
        description: "Join The Main Bagel's eLoyalty program to embark on an exclusive journey of culinary rewards and appreciation. With 15 years of family-owned excellence, our commitment extends to our loyal customers. As a member, savor the perks of authentic New York-style delights, early access to promotions, and personalized offerings. Immerse yourself in the essence of tradition and community with every eLoyalty reward. Elevate your experience with us and enjoy the finest bagels, breakfast delights, and a host of special privileges.",
        keywords: "eLoyalty, The Main Bagel, culinary rewards, appreciation, family-owned, commitment, New York-style, perks, promotions, personalized offerings, tradition, community, bagels, breakfast delights, special privileges, sign up, loyalty program",
        message: "Sign up now and become part of The Main Bagel family where loyalty meets culinary delight.",
    },
};

export default config;